<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5 justify-content-between">
      <div class="col-4" v-if="isEnabled('fnImpiantiAddImpianto')">
        <div>
          <router-link
            to="/impianti/add-impianto"
            class="btn btn-light text-gray-700 fs-6 text-start"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>aggiungi anagrafica
            impianto
          </router-link>
        </div>
      </div>
      <div
        class="col-4"
        :class="isEnabled('fnImpiantiAddImpianto') ? 'text-center' : ''"
      >
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6"
          data-bs-toggle="modal"
          data-bs-target="#modal_esporta_impianti"
        >
          <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta lista
          filtrata
        </button>
      </div>
      <ModalEsportaImpianti />
      <div class="col-4">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b
            >{{ rowsToSkip + fetchRows - (fetchRows - 1) }} -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}</b
          >
          Impianti di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div v-if="status === 200 || !status">
      <div v-if="loaded && record != 0">
        <Datatable
          :table-header="tableHeader"
          :table-data="impianti_list"
          :total="record"
          :current-page="currentPage"
          :rows-per-page="fetchRows"
          :sortLabel="sortColumn"
          :order="sortOrder"
          @sort="onSort"
          @items-per-page-change="onChangeFetchRow"
          @current-change="onPageChange"
        >
          <template v-slot:cell-denominazione="{ row: data }">
            <router-link :to="`/impianti/dettaglio-impianto/${data.id}`">
              <div class="tab_long">
                {{ data.denominazione }}
              </div></router-link
            >
          </template>
          <template v-slot:cell-localita="{ row: data }">
            <div class="tab_long">
              {{ data.localita }}
            </div>
          </template>
          <!-- <template v-slot:cell-db_provenienza="{ row: data }">
            {{ data.db_provenienza }}
          </template> -->
          <!-- <template v-slot:cell-indirizzo="{ row: data }">
            {{ data.indirizzo }}
          </template>
          <template v-slot:cell-comitato="{ row: data }">
            {{ data.comitato }}
          </template> -->

          <template v-slot:cell-options="{ row: data }">
            <!-- prendi la posizione del bottone
            assegan le coordinate (:style su ul class="dropdown-menu" con transform: translate(AsseX px, AsseYpx);)
            gestire responsive  -->
            <div class="btn-group">
              <button
                v-if="!readOnly"
                class="btn btn-sm dropdown"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
              </button>
              <button v-if="readOnly">
                <router-link
                  :to="{
                    name: 'dettaglio-impianto',
                    params: {
                      id_impianto: data.id,
                      onlyView: true,
                    },
                  }"
                  class="navi-link"
                >
                  <i class="bi bi-eye text-dark fs-5"></i>
                  <span class="text-dark ps-1 fs-7"></span>
                </router-link>
              </button>
              <ul class="dropdown-menu">
                <router-link
                  :to="{
                    name: 'dettaglio-impianto',
                    params: {
                      id_impianto: data.id,
                      onlyView: true,
                    },
                  }"
                  v-if="isEnabled('fnImpiantiViewImpianto')"
                >
                  <li role="presentation" class="navi-item">
                    <div class="b-dropdown-text text-start py-2 px-4">
                      <a href="#" class="navi-link">
                        <i class="bi bi-eye text-dark fs-5"></i>
                        <span class="text-dark ps-1 fs-7"> Visualizza </span>
                      </a>
                    </div>
                  </li>
                </router-link>
                <router-link
                  :to="{
                    name: 'dettaglio-impianto',
                    params: {
                      id_impianto: data.id,
                      onlyView: false,
                    },
                  }"
                  v-if="!readOnly"
                >
                  <li role="presentation" class="navi-item">
                    <div class="b-dropdown-text text-start py-2 px-4">
                      <div class="navi-link">
                        <i class="bi bi-pen text-dark fs-5"></i>
                        <span class="text-dark ps-1 fs-7"> Modifica </span>
                      </div>
                    </div>
                  </li></router-link
                >
                <router-link
                  :to="{
                    name: 'lista-omologazioni-impianto',
                    params: {
                      id_impianto: data.id,
                      denominazione_impianto: data.denominazione,
                    },
                  }"
                  v-if="!readOnly"
                >
                  <li role="presentation" class="navi-item">
                    <div class="b-dropdown-text text-start py-2 px-4">
                      <div class="navi-link">
                        <i class="bi bi-bookmark-check text-dark fs-5"></i>
                        <span class="text-dark ps-1 fs-7"> Omologazioni </span>
                      </div>
                    </div>
                  </li></router-link
                >
                <a
                  href="#"
                  @click="deleteImpianto(data.id)"
                  v-if="isEnabled('fnImpiantiDeleteImpianto')"
                >
                  <li role="presentation" class="navi-item">
                    <div class="b-dropdown-text text-start py-2 px-4">
                      <div class="navi-link">
                        <i class="bi bi-trash text-dark fs-5"></i>
                        <span class="text-dark ps-1 fs-7"> Elimina </span>
                      </div>
                    </div>
                  </li></a
                >
              </ul>
            </div>
          </template>
        </Datatable>
      </div>
      <div class="mx-auto my-auto text-center" v-else-if="!loaded">
        <div class="spinner-border text-gray-600" role="status"></div>
        <span class="text-gray-600 ps-3 fs-2">Loading</span>
      </div>
      <div
        v-else-if="record === 0"
        class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
      >
        <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
        <span class="text-gray-700 ps-3 fs-2">
          Attenzione! Nessun risultato.
        </span>
        <span
          type="button"
          data-bs-toggle=""
          data-bs-target=""
          aria-expanded="false"
          aria-controls=""
          class="text-gray-600 pt-4"
          @click.prevent="$emit('resetFilters')"
        >
          <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
        ></span>
        <!-- <span class="sr-only">Loading...</span> -->
      </div>
    </div>
    <div
      v-else
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
      <!-- <span class="sr-only">Loading...</span> -->
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted, onUnmounted } from "vue-demi";
import { useStore } from "vuex";
import Datatable from "../../kt-datatable/KTDatatable.vue";

import { alertSuccess } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import isEnabled from "@/composables/isEnabled.js";

import { delImpianto } from "@/requests/addImpianto";
import ModalEsportaImpianti from "./ModalEsportaImpianti.vue";

export default {
  name: "TableImpianto",

  components: {
    Datatable,
    Loading,
    ModalEsportaImpianti,
  },
  emits: ["getImpianti", "resetFilters"],

  setup(props, { emit }) {
    const isLoading = ref(false);

    const deleteImpianto = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "L'impianto verrà eliminato definitivamente.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await delImpianto(id).then((res) => {
            if (res.status == 200) {
              alertSuccess("Impianto eliminato correttamente");
              emit("getImpianti");
            } else {
              const strMsg = ref(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
              Swal.fire({
                html: strMsg.value,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            }
            isLoading.value = false;
          });
        }
      });
    };

    const store = useStore();

    function changeFetchRows(num) {
      store.commit("setFetchRowsImpianti", num);
      emit("getImpianti");
    }

    function onSort({ columnName, order }) {
      store.commit("setSortColumnImpianti", columnName);
      store.commit("setSortOrderImpianti", order);
      emit("getImpianti");
    }

    function onChangeFetchRow(num) {
      store.commit("setFetchRowsImpianti", num);
      store.commit("setRowsToSkipImpianti");
      emit("getImpianti");
    }

    function onPageChange(page) {
      // store.commit("setPage", page);
      // emit("getImpianti")
      store.commit("setPageImpianti", page);
      emit("getImpianti");
    }

    const tableHeader = ref([
      {
        name: "Denominazione",
        key: "denominazione",
      },
      {
        name: "Località",
        key: "localita",
        sortable: false,
      },
      // {
      //   name: "Provenienza Dato",
      //   key: "db_provenienza",
      // },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const esportaLista = () => {
      Swal.fire({
        html: "Funzionalità presto disponibile",
        icon: "warning",
        buttonsStyling: false,
        confirmButtonText: "Chiudi",
        customClass: {
          confirmButton: "btn fw-bold btn-light-warning",
        },
      });
    };

    return {
      impianti: computed(() => store.getters.impianti),
      totalPages: computed(() => store.getters.totalPagesImpianti),
      currentPage: computed(() => store.getters.currentPageImpianti),
      error: computed(() => store.getters.errorImpianti),
      loading: computed(() => store.getters.loadingImpianti),
      totalImpianti: computed(() => store.getters.totalImpianti),
      fetchRows: computed(() => store.getters.fetchRowsImpianti),
      rowsToSkip: computed(() => store.getters.rowsToSkipImpianti),
      sortColumn: computed(() => store.getters.sortColumnImpianti),
      sortOrder: computed(() => store.getters.sortOrderImpianti),
      onPageChange,
      changeFetchRows,
      onSort,
      onChangeFetchRow,
      tableHeader,
      isEnabled,

      deleteImpianto,
      isLoading,

      esportaLista,

      impianti_list: computed(() =>
        store.getters.getStateFromName("resultsimpianti_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedimpianti_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordimpianti_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusimpianti_list")
      ),
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
};
</script>

<style>
.fixed-dropdown {
  min-width: 12rem !important;
  position: fixed !important;
}
</style>
